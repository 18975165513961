@font-face {
  font-family: "Menlo";
  src: url("./assets/fonts/Menlo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  background: #ffffff;
}

* {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
